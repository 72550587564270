.edit-dashboard {
    max-width: 10rem;
    margin-bottom: 3rem;
}

.edit-dashboard button {
    text-align: start;
    background: none;
    border: none;
    margin: 0.5rem 0rem 0rem 1rem;
}

.edit-dashboard .btn-primary {
    --bs-btn-color: black;
}

.btn-alt {
    width: fit-content;
}

.search-btn {
    background: #7a7d80 !important;
}

.search-btn:hover {
    background: #616466 !important;
}