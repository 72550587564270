.edit-modal {
    --bs-modal-width: 1500px !important;
}

.id-display {
    font-size: medium;
    font-weight: 200;
}

.placeholder-zone-loading {
    height: 50rem;
}

.spinner {
    margin-top: 20rem;
    border: 4px solid rgba(255, 255, 255, 0.1);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border-left-color: #09f;
  
    animation: spin 1s ease infinite;
  }

    /* honey-pot */
.not-today {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
    