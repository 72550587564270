.post-btn {
    width: 20rem;
    padding: 0.5rem 1rem;
}

.clear-btn {
    width: 15rem;
    padding: 0.5rem 1rem;
}

.input-image {
    max-width: 20rem;
    max-height: 20rem;
}

.placeholder-zone-loading {
    height: 50rem;
}

.spinner {
    margin-top: 20rem;
    border: 4px solid rgba(255, 255, 255, 0.1);
    width: 75px;
    height: 75px;
    border-radius: 50%;
    border-left-color: #09f;
  
    animation: spin 1s ease infinite;
  }

  /* honey-pot */
.not-today {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
}
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
