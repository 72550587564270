.items-container-heading {
    text-align: center;
    margin: auto;
}

.inventory-cell-container {
    margin: 0 3rem;
    min-height: 25rem;
}

.no-items-message {
    width: fit-content;
    margin: auto;
    padding-top: 10rem;
}

.pagination-buttons {
    width: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
