.App {
  background-color: #ACACAC;
}

.container {
  background-color: #E2E2E2;
  max-width: 1620px!important;
}

h2 {
  --bs-heading-color: #565656;
}