.edit-inventory-cell .card {
    background: transparent;
    border: none;
}


.card-body {
    background-color: #cecece;
    border: #a7a7a7 solid 1px;
}

.card-img-top {
    object-fit: cover;
    height: 8rem;
}

.inventory-name-text {
    font-weight: var(--font-weight-bold);
}

.cell-btns {
    display: flex;
    justify-content: space-between;
}

.date {
    font-size: 0.75rem;
}


